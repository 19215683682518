import { useStore } from "../store"

// Define translation strings
const translations = {
  "Mitt konto": {
    en: "My account",
  },
  Projekt: {
    en: "Project",
  },

  "Mina projekt": {
    en: "My projects",
  },

  "Nytt projekt": {
    en: "New project",
  },

  "Skapa nytt projekt": {
    en: "Create new project",
  },

  "Skapa projekt": {
    en: "Create project",
  },
  Bilpool: {
    en: "Car pool",
  },

  "Logga ut": {
    en: "Sign out",
  },

  Anteckningar: {
    en: "Notes",
  },

  "Skriv dina anteckningar...": {
    en: "Type in your notes here...",
  },

  Resultat: {
    en: "Results",
  },

  "Total parkering": {
    en: "Total parking",
  },

  "Total parkeringsbehov": {
    en: "Total parking demand",
  },

  Differens: {
    en: "Difference",
  },

  "Total investering (MSEK)": {
    en: "Total investment (MSEK)",
  },

  "Klimatpåverkan CO2": {
    en: "Climate impact CO2",
  },

  "Hög påverkan": {
    en: "High impact",
  },

  "Medium påverkan": {
    en: "Medium impact",
  },

  "Låg påverkan": {
    en: "Low impact",
  },

  Lönsamhet: {
    en: "Profitability",
  },

  "Hög lönsamhet": {
    en: "High profitability",
  },
  "Break-even": {
    en: "Break-even",
  },
  "Låg lönsamhet": {
    en: "Low profitability",
  },

  "Rörelsehindrade (Antal)": {
    en: "Disabled (Number)",
  },

  "Besöksparkering (Antal)": {
    en: "Visitor parking (Number)",
  },

  Flerbostadshus: {
    en: "-",
  },

  Studentbostäder: {
    en: "-",
  },

  Vårdbostäder: {
    en: "-",
  },

  Kontor: {
    en: "-",
  },
  Lager: {
    en: "-",
  },
  Sjukhus: {
    en: "-",
  },
  Universitet: {
    en: "-",
  },
  Skola: {
    en: "-",
  },
  Förskola: {
    en: "-",
  },
  Handel: {
    en: "-",
  },
  "Småhus gemensam P": {
    en: "-",
  },
  "Småhus tomtparkering": {
    en: "-",
  },
  Hotell: {
    en: "-",
  },
  Flerbostadshus: {
    en: "-",
  },
  Studentbostäder: {
    en: "-",
  },
  Vårdbostäder: {
    en: "-",
  },
  Kontor: {
    en: "-",
  },
  Lager: {
    en: "-",
  },
  Sjukhus: {
    en: "-",
  },
  Universitet: {
    en: "-",
  },
  Skola: {
    en: "-",
  },
  Förskola: {
    en: "-",
  },
  Handel: {
    en: "-",
  },
  "Småhus gemensam P": {
    en: "-",
  },
  "Småhus tomtparkering": {
    en: "-",
  },
  Hotell: {
    en: "-",
  },

  "kvm BTA": {
    en: "sqm BTA",
  },

  Lägenhet: {
    en: "Apartment",
  },

  Rum: {
    en: "Room",
  },

  Mobilitetsåtgärder: {
    en: "-",
  },

  Mobilitetsåtgärd: {
    en: "-",
  },

  Byggnation: {
    en: "-",
  },

  Reduktion: {
    en: "-",
  },

  Select: {
    en: "-",
  },

  "Total (Brutto)": {
    en: "Total (Net)",
  },

  Egenskap: {
    en: "-",
  },

  Värde: {
    en: "-",
  },

  Kommun: {
    en: "Municipality",
  },

  Zon: {
    en: "Zone",
  },

  Beskrivning: {
    en: "Description",
  },

  //   "Bilpool med pköp": {
  //     en: "Car pool with...",
  //   },
}

export const useTranslate = () => {
  const locale = useStore((state) => state.language) // Reactively watch `language`

  const translateString = (word) => {
    if (translations.hasOwnProperty(word) && translations[word][locale]) {
      return translations[word][locale]
    }
    return word
  }

  return translateString
}
