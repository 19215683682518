import { Button } from "react-bootstrap"
import Accordion from "../common/Accordion"
import Input from "../common/Input"
import Tooltip from "../common/Tooltip"
import { isValidElement, useState } from "react"
import { MdCheck, MdDeleteForever } from "react-icons/md"
import Select from "../common/Select"
import { useDebouncedCallback } from "use-debounce"
import { useTranslate } from "../../lib/localization"

const Reductions = ({ project, zone, onProjectChange, editDisabled }) => {
  const construction = project.construction
  const reductions = project.reductions

  const translate = useTranslate()

  const [manualReductionType, setManualReductionType] = useState("multiFamily")
  const [manualReductionDesc, setManualReductionDesc] = useState()
  const [manualReductionReduction, setManualReductionReduction] = useState()
  const [manualReductionIsValid, setManualReductionIsValid] = useState(false)
  const [existingLoadReductionDesc, setExistingLoadReductionDesc] = useState(
    project.existingLoadReductionDescription,
  )
  const [existingLoadReductionPerc, setExistingLoadReductionPerc] = useState(
    project.existingLoadReductionPercentage,
  )

  const activeConstruction = construction.filter((entry) => entry.value > 0)

  const existingLoadAfterReduction =
    project.existingLoadReductionPercentage != null &&
    Number(
      project.existingLoad *
        (1 - project.existingLoadReductionPercentage / 100),
    ).toFixed(1)

  const existingLoadDifference =
    project.existingLoadReductionPercentage != null &&
    Number(
      project.existingLoad *
        (1 - project.existingLoadReductionPercentage / 100) -
        project.existingLoad,
    ).toFixed(1)

  const totalRequiredBeforeReduction =
    Number(
      project.construction.reduce((acc, entry) => acc + entry.required, 0),
    ) + Number(project.existingLoad)

  const totalRequiredAfterReduction =
    construction.reduce((acc, entry) => {
      const applicableReductions = reductions.filter(
        (reduction) =>
          reduction.selected === true && reduction.type === entry.type,
      )

      // Calculate cumulative reduction effect
      const totalReductionFactor = applicableReductions.reduce(
        (reductionAcc, reduction) => reductionAcc * (1 - reduction.reduction),
        1, // Start with no reduction
      )

      const requiredAfterReduction = entry.required * totalReductionFactor

      return acc + requiredAfterReduction
    }, 0) + Number(existingLoadAfterReduction)

  const handleInputChange = (index, e) => {
    const selectedEntry = reductions[index]
    const checked = e.target.checked

    const newReductions = reductions.map((entry, reductionIndex) => {
      if (index == reductionIndex) {
        console.log(entry)
        return {
          ...entry,
          selected: checked,
        }
      }

      if (entry.type === selectedEntry.type)
        return {
          ...entry,
          // If only one can be selected
          // selected: false,
        }
      else return entry
    })

    const newProject = {
      ...project,
      reductions: newReductions,
    }

    onProjectChange(newProject)
  }

  const handleInputExistingLoadReductionDesc = (value) => {
    setExistingLoadReductionDesc(value)
  }

  const handleInputExistingLoadReductionPercDelay = useDebouncedCallback(
    (value) => {
      const newProject = {
        ...project,
        existingLoadReductionDescription: existingLoadReductionDesc,
        existingLoadReductionPercentage: value,
      }
      onProjectChange(newProject)
    },
    1000,
  )

  const handleInputExistingLoadReductionPerc = (newValue) => {
    setExistingLoadReductionPerc(newValue) // Uppdaterar lokal state omedelbart
    handleInputExistingLoadReductionPercDelay(newValue) // Debouncerad uppdatering av projektet
  }

  const handleSaveManualReduction = () => {
    const selectedEntry = construction.find(
      (entry) => entry.type === manualReductionType,
    )

    const tempReduction = {
      type: manualReductionType,
      reduction: manualReductionReduction / 100,
      selected: false,
      desc: manualReductionDesc,
      descType: selectedEntry.desc,
    }

    setManualReductionDesc("")
    setManualReductionReduction(0)
    setManualReductionType("multiFamily")
    setManualReductionIsValid(false)

    const tempReductions = [...reductions, tempReduction]

    const newProject = {
      ...project,
      reductions: tempReductions,
    }

    onProjectChange(newProject)
  }

  const handleInputChangeManualReductionType = (value) => {
    setManualReductionType(value)
    const reduction = {
      desc: manualReductionDesc,
      reduction: manualReductionReduction,
      type: value,
    }
    const isValid = validateManualReduction(reduction)
    setManualReductionIsValid(isValid)
  }

  const handleInputChangeManualReductionDesc = (value) => {
    setManualReductionDesc(value)
    const reduction = {
      desc: value,
      reduction: manualReductionReduction,
      type: manualReductionType,
    }
    const isValid = validateManualReduction(reduction)
    setManualReductionIsValid(isValid)
  }

  const handleInputChangeManualReductionReduction = (value) => {
    setManualReductionReduction(value)
    const reduction = {
      desc: manualReductionDesc,
      reduction: value,
      type: manualReductionType,
    }
    const isValid = validateManualReduction(reduction)
    setManualReductionIsValid(isValid)
  }

  const validateManualReduction = (reduction) => {
    return (
      reduction.desc &&
      reduction.reduction &&
      reduction.reduction >= 0 &&
      reduction.reduction <= 100
    )
  }

  return (
    <div className="toolbar-box">
      <Accordion title={translate("Mobilitetsåtgärder")}>
        <table className="toolbar-table">
          <thead>
            <tr>
              <th>
                <div className="subtitle">{translate("Mobilitetsåtgärd")}</div>
              </th>
              <th>
                <div className="subtitle">{translate("Byggnation")}</div>
              </th>
              <th>
                <div className="subtitle">{translate("Reduktion")}</div>
              </th>
              <th>
                <div className="subtitle">{translate("Select")}</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {reductions.map(({ desc, type, reduction, selected }, index) => (
              <tr key={index}>
                <td>
                  <Tooltip
                    text={
                      desc == "Bilpool"
                        ? "Detta är info om bilpool"
                        : desc == "Bilpool med pköp"
                          ? "Detta är info om bilpool med pköp"
                          : desc == "Bilfritt boende"
                            ? "Detta är info om bilfritt boende"
                            : "Detta är info om Grön resplan"
                    }
                    xPosition="left"
                    yPosition="top"
                    showIcon
                  >
                    {desc}
                  </Tooltip>
                </td>
                <td>{construction.find((c) => c.type === type).desc}</td>
                <td>{reduction * 100 + "%"}</td>
                <td>
                  <Input
                    isDisabled={editDisabled}
                    type="checkbox"
                    checked={selected}
                    onChange={(e) => handleInputChange(index, e)}
                    transparentBackground
                  />
                </td>
              </tr>
            ))}

            <tr>
              <td>
                <Tooltip
                  text={"Här kan du lägga till mobilitetsåtgärder"}
                  xPosition="left"
                  yPosition="top"
                  showIcon
                >
                  <Input
                    type="text"
                    className="input-text"
                    invertColor
                    transparentBackground
                    placeholder="Beskrivning"
                    value={manualReductionDesc}
                    onChange={(e) =>
                      handleInputChangeManualReductionDesc(e.target.value)
                    }
                    isDisabled={editDisabled}
                  ></Input>
                </Tooltip>
              </td>
              <td>
                <Select
                  invertColor
                  transparentBackground
                  isDisabled={editDisabled}
                  value={manualReductionType}
                  onChange={(e) =>
                    handleInputChangeManualReductionType(e.target.value)
                  }
                >
                  {construction.map(({ desc, type }, i) => (
                    <option key={type} value={type}>
                      {desc}
                    </option>
                  ))}
                </Select>
              </td>
              <td>
                <Input
                  type="number"
                  min={0}
                  max={100}
                  step={1}
                  className="input-number"
                  placeholder={0}
                  innerLabel
                  invertColor
                  isDisabled={editDisabled}
                  transparentBackground
                  value={manualReductionReduction}
                  onChange={(e) =>
                    handleInputChangeManualReductionReduction(e.target.value)
                  }
                  label="%"
                ></Input>
              </td>
              <td>
                <Button
                  disabled={!manualReductionIsValid || editDisabled}
                  className="btn btn-success"
                  onClick={() => handleSaveManualReduction()}
                >
                  <MdCheck />
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="toolbar-table mt-4">
          <thead>
            <tr>
              <th>
                <div className="subtitle">Reduktion befintlig belastning</div>
              </th>
              <th>
                <div className="subtitle">%</div>
              </th>
            </tr>
            <tr>
              <td>
                <Input
                  type="text"
                  className="input-text"
                  invertColor
                  transparentBackground
                  placeholder="Beskrivning"
                  value={existingLoadReductionDesc}
                  onChange={(e) =>
                    handleInputExistingLoadReductionDesc(e.target.value)
                  }
                  isDisabled={editDisabled}
                ></Input>
              </td>
              <td>
                <Input
                  type="number"
                  min={0}
                  max={100}
                  step={1}
                  className="input-number"
                  placeholder={0}
                  innerLabel
                  invertColor
                  isDisabled={editDisabled}
                  transparentBackground
                  value={existingLoadReductionPerc}
                  onChange={(e) =>
                    handleInputExistingLoadReductionPerc(e.target.value)
                  }
                  label="%"
                ></Input>
              </td>
            </tr>
          </thead>
        </table>
        <table className="toolbar-table mt-4">
          <thead>
            <tr>
              <th>
                <div className="subtitle"></div>
              </th>
              <th>
                <div className="subtitle">Behov innan reduktion</div>
              </th>
              <th>
                <div className="subtitle">Behov efter reduktion</div>
              </th>
              <th>
                <div className="subtitle">Skillnad</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {activeConstruction.map(
              ({ type, desc, value, unit, required }, index) => {
                // Get all selected reductions for this type
                const applicableReductions = reductions.filter(
                  (reduction) =>
                    reduction.selected === true && reduction.type === type,
                )

                // Calculate the total reduction factor
                const totalReductionFactor = applicableReductions.reduce(
                  (acc, reduction) => acc * (1 - reduction.reduction),
                  1, // Start with a multiplier of 1
                )

                // Apply the total reduction factor to required
                const requiredAfterReduction = required * totalReductionFactor

                return (
                  <tr className="is-totals" key={index}>
                    <td>{desc}</td>
                    <td>{required.toFixed(1)}</td>
                    <td>{requiredAfterReduction.toFixed(1)}</td>
                    <td>{(requiredAfterReduction - required).toFixed(1)}</td>
                  </tr>
                )
              },
            )}
            {project.existingLoad > 0 && (
              <tr className="is-totals">
                <td>Befintliga parkeringar</td>
                <td>{project.existingLoad}</td>
                <td>{existingLoadAfterReduction}</td>
                <td>{existingLoadDifference}</td>
              </tr>
            )}
            <tr className="is-totals fw-bold">
              <td>Total</td>
              <td>{totalRequiredBeforeReduction.toFixed(1)}</td>
              <td>{totalRequiredAfterReduction.toFixed(1)}</td>
              <td>
                {(
                  totalRequiredAfterReduction - totalRequiredBeforeReduction
                ).toFixed(1)}
              </td>
            </tr>
          </tbody>
        </table>
      </Accordion>
    </div>
  )
}

export default Reductions
