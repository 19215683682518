import cn from "clsx"
import s from "./Message.module.scss"
import { MdError, MdInfo } from "react-icons/md"

const Message = ({ children, size, type = "success", ...props }) => {
  return (
    <div
      className={cn(
        s.container,
        // Type
        type == "success" || (type == "info" && s.success),
        type == "error" && s.error,

        // Size
        size == "small" && s.small,
      )}
      {...props}
    >
      {type == "error" && <MdError />}
      {type == "info" && <MdInfo />}

      {children}
    </div>
  )
}

export default Message
