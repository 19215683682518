import cn from "clsx"
import s from "./Button.module.scss"

const Button = ({
  children,
  outline,
  icon,
  ghost,
  invertColor,
  autoWidth,
  backgroundColor,
  size = "small",
  ...props
}) => {
  return (
    <button
      className={cn(
        s.container,
        outline && s.outline,
        ghost && s.ghost,
        icon && s.hasIcon,
        invertColor && s.invertColor,
        autoWidth && s.autoWidth,
        size == "large" && s.large,
        backgroundColor == "darkGreen" && s.darkGreen,
      )}
      {...props}
    >
      {children}

      {icon && <div className={s.icon}>{icon}</div>}
    </button>
  )
}

export default Button
