import { Col, Container, Row } from "react-bootstrap"
import s from "./Layout.module.scss"
import { MdAccountCircle, MdHome } from "react-icons/md"
import { useState } from "react"
import Dropdown from "../common/Dropdown"
import { Link } from "react-router-dom"
import Button from "../common/Button"

const Layout = ({ children }) => {
  const [toggleDropdown, setToggleDropdown] = useState(false)
  return (
    <main className={s.main}>
      <div className={s.navWrapper}>
        <div className={s.navbar}>
          <div className={s.navbarContent}>
            <div>
              <Link to="/">
                <div style={{ width: "150px" }}>
                  <img
                    src="/parkyria-logo.svg"
                    width="100%"
                    loading="lazy"
                    alt="Parkyria logo"
                  />
                </div>
              </Link>
            </div>
            <div style={{ position: "relative" }}>
              <Button
                size="large"
                aria-label="Mitt konto"
                backgroundColor="darkGreen"
                onClick={() => setToggleDropdown(!toggleDropdown)}
              >
                MITT KONTO
              </Button>
              <Dropdown show={toggleDropdown} position="bottom">
                <li>
                  <Link to="/logout">Logga ut</Link>
                </li>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <div className={s.container}>{children}</div>
    </main>
  )
}

export default Layout
