import { useEffect, useState } from "react"
import s from "./Barometer.module.scss"
import cn from "clsx"
import Message from "../Message"
import Modal from "../Modal"

const Barometer = ({
  title,
  percent = 0,
  disclaimer,
  disclaimerText,
  items = [],
}) => {
  const [activeItem, setActiveItem] = useState(null)
  const [showDisclaimer, setShowDisclaimer] = useState(false)

  useEffect(() => {
    // Clamp the percent value between 0 and 100
    const clampedPercent = Math.min(100, Math.max(0, percent))

    // Calculate the width of each item
    const itemWidth = 100 / items.length

    // Find the active item based on the clamped percent value
    const activeItemIndex =
      clampedPercent === 100
        ? items.length - 1
        : Math.floor(clampedPercent / itemWidth)
    setActiveItem(items[activeItemIndex])
  }, [percent, items])

  const handleCloseDisclaimer = () => {
    setShowDisclaimer(false)
  }

  return (
    <div className={cn(s.container)}>
      <div className={s.heading}>
        <div> {title}</div>
        <div>
          {activeItem && (
            <div
              className={s.status}
              style={{ background: `${activeItem.color}` }}
            >
              {activeItem.title}
            </div>
          )}
        </div>
      </div>

      <div className={s.barWrap}>
        <div
          className={s.slider}
          style={{ left: `${Math.min(100, Math.max(0, percent))}%` }}
        />

        <div className={s.bar}>
          {items?.map((item) => (
            <div className={s.item} key={item.title}>
              <div className={s.itemBar} style={{ background: item.color }} />
              <span>{item.title}</span>
            </div>
          ))}
        </div>
      </div>

      {disclaimer && (
        <div className={s.disclaimer}>
          <Message
            type="info"
            size="small"
            onClick={() => setShowDisclaimer(true)}
          >
            {disclaimer}
          </Message>

          <Modal
            title={disclaimer}
            show={showDisclaimer}
            onHide={handleCloseDisclaimer}
          >
            {disclaimerText}
          </Modal>
        </div>
      )}
    </div>
  )
}

export default Barometer
