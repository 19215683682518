import cn from "clsx"
import { useEffect, useState } from "react"
import { initProjectAndZone, saveProject } from "./Api"
import { LoadingSpinner } from "./common/Icons"
import Construction from "./project/Construction"
import Parking from "./project/Parking"
import ProjectDetails from "./project/ProjectDetails"
import ProjectHeader from "./project/ProjectHeader"
import Reductions from "./project/Reductions"
import Results from "./project/Results"
import Notes from "./project/Notes"

const Project = ({
  id,
  projects,
  setProjects,
  setSelectedProject,
  setCoordinates,
}) => {
  const [hasSaved, setHasSaved] = useState(false)
  const [project, setProject] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [zone, setZone] = useState()

  useEffect(() => {
    initProjectAndZone(id, setProject, setZone)
  }, [id])

  const onProjectChange = (newProject) => {
    saveProject(newProject, setProject)
    setHasSaved(true)
  }

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setHasSaved(false)
    }, 800)

    return () => {
      clearTimeout(timeOut)
    }
  }, [hasSaved])

  useEffect(() => {
    setIsLoading(true)

    const timeOut = setTimeout(() => {
      setIsLoading(false)
    }, 600)

    return () => {
      clearTimeout(timeOut)
    }
  }, [id])

  return (
    <>
      <Results />

      <div className="toolbar-inner">
        <ProjectHeader
          currentProject={project}
          projects={projects}
          setProjects={setProjects}
          setSelectedProject={setSelectedProject}
          setCoordinates={setCoordinates}
          hasSaved={hasSaved}
        />

        {isLoading && (
          <div className="toolbar-loading">
            <LoadingSpinner />
          </div>
        )}

        <div className={cn("toolbar-content", isLoading && "is-loading")}>
          {project && zone && (
            <ProjectDetails
              project={project}
              onProjectChange={onProjectChange}
              header="Projekt"
            />
          )}
          {project && zone && (
            <Notes project={project} onProjectChange={onProjectChange} />
          )}
          {project && zone && (
            <Construction
              zone={zone}
              project={project}
              onProjectChange={onProjectChange}
            />
          )}
          {project && zone && (
            <Reductions
              project={project}
              zone={zone}
              onProjectChange={onProjectChange}
            />
          )}
          {project && zone && (
            <Parking project={project} onProjectChange={onProjectChange} />
          )}
        </div>
      </div>
    </>
  )
}

export default Project
